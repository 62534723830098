import actions from './actions.js';
import reducers from './reducers';
import localStorage from './localStorage.js';
import sessionStorage from './sessionStorage.js';
import * as constants from './constants/ActionTypes';

const redux = {
  reducers,
  actions,
  constants,
  localStorage,
  sessionStorage
};

export default redux;
