/**
 * Entry point for development and production PWA builds.
 * Packaged (NPM) builds go through `index-umd.js`
 */

import 'regenerator-runtime/runtime';

import App from './App.js';
import React from 'react';
import ReactDOM from 'react-dom';
// test

/**
 * EXTENSIONS
 * =================
 *
 * Importing and modifying the extensions our app uses HERE allows us to leverage
 * tree shaking and a few other niceties. However, by including them here they become
 * "baked in" to the published application.
 *
 * Depending on your use case/needs, you may want to consider not adding any extensions
 * by default HERE, and instead provide them via the extensions configuration key or
 * by using the exported `App` component, and passing in your extensions as props using
 * the defaultExtensions property.
 */
import OHIFVTKExtension from '@ohif/extension-vtk';
import OHIFDicomHtmlExtension from '@ohif/extension-dicom-html';
import OHIFDicomSegmentationExtension from '@ohif/extension-dicom-segmentation';
import OHIFDicomRtExtension from '@ohif/extension-dicom-rt';
import OHIFDicomMicroscopyExtension from '@ohif/extension-dicom-microscopy';
import OHIFDicomPDFExtension from '@ohif/extension-dicom-pdf';
import OHIFDicomTagBrowserExtension from '@ohif/extension-dicom-tag-browser';
// Add this for Debugging purposes:
//import OHIFDebuggingExtension from '@ohif/extension-debugging';
import { version } from '../package.json';
import queryString from 'query-string';

/*
 * Default Settings
 */

let config = queryString.parse(window.location.search, {
  parseBooleans: true,
  parseNumbers: true,
}).config;

if (config !== undefined) {
  config = JSON.parse(config);
}

if (window) {
  if (config === undefined) {
    if (sessionStorage.getItem('external_config') !== null) {
      config = JSON.parse(sessionStorage.getItem('external_config'));
      //console.log('OHIF config loaded from session storage.');
    } else {
      config = window.config || {};
      //console.log('OHIF config loaded from file.');
    }
  } else {
    //console.log('Saving OHIF config.');
    sessionStorage.setItem('external_config', JSON.stringify(config));
    //console.log('OHIF config passed as query string.');
  }
  window.version = version;
}

const appProps = {
  config,
  defaultExtensions: [
    OHIFVTKExtension,
    OHIFDicomHtmlExtension,
    OHIFDicomMicroscopyExtension,
    OHIFDicomPDFExtension,
    OHIFDicomSegmentationExtension,
    OHIFDicomRtExtension,
    //OHIFDebuggingExtension,
    OHIFDicomTagBrowserExtension,
  ],
};

/** Create App */
const app = React.createElement(App, appProps, null);

/** Render */
ReactDOM.render(app, document.getElementById('root'));
