import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Checkbox } from '@ohif/ui';
import { useTranslation } from 'react-i18next';

import './view-options-toolbar-button.styl';

const ToolbarLabel = props => {
  const { label } = props;
  return <div className="toolbar-button-label">{label}</div>;
};

ToolbarLabel.propTypes = {
  label: PropTypes.string.isRequired,
};

const _getClassNames = (isActive, className) => {
  return classnames('toolbar-button', 'slab-thickness', className, {
    active: isActive,
  });
};

const _applyRefLineOperation = (
  operation,
  refLinesChecked,
  toolbarClickCallback,
  button
) => {
  // in case refLinesChecked has not being triggered by user yet
  if (typeof refLinesChecked !== 'boolean') {
    return;
  }

  const { refLineToggle } = button;

  const _operation = refLinesChecked ? operation : refLineToggle;
  if (toolbarClickCallback && _operation) {
    toolbarClickCallback(_operation, { refLinesChecked: refLinesChecked });
  }
};

const _getInitialState = currentSelectedOption => {
  return {
    refLinesChecked: true,
    operation: currentSelectedOption,
  };
};

const INITIAL_OPTION_INDEX = 0;
const _getInitialtSelectedOption = (button = {}) => {
  return button.refLineToggle;
};

function ViewOptionsToolbarButton({
  parentContext,
  toolbarClickCallback,
  button,
  activeButtons,
  isActive,
  className,
}) {
  const currentSelectedOption = _getInitialtSelectedOption(button);
  const [state, setState] = useState(_getInitialState(currentSelectedOption));
  const _className = _getClassNames(isActive, className);
  const { refLineToggle } = button;

  function onChangeRefLinesCheckbox(checked) {
    // find select value
    const operation = refLineToggle;
    setState({ ...state, operation, refLinesChecked: checked });
  }

  useEffect(() => {
    _applyRefLineOperation(
      state.operation,
      state.refLinesChecked,
      toolbarClickCallback,
      button
    );
  }, [state.operation, state.refLinesChecked]);

  const [t] = useTranslation('Buttons');

  return (
    <div className={_className}>
      <div className="controller">
        <Checkbox
          label={t('Reference Lines')}
          checked={state.refLinesChecked}
          onChange={onChangeRefLinesCheckbox}
        />
      </div>
    </div>
  );
}

ViewOptionsToolbarButton.propTypes = {
  parentContext: PropTypes.object.isRequired,
  toolbarClickCallback: PropTypes.func.isRequired,
  button: PropTypes.object.isRequired,
  activeButtons: PropTypes.array.isRequired,
  isActive: PropTypes.bool,
  className: PropTypes.string,
};

export default ViewOptionsToolbarButton;
