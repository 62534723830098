import React, {
  useRef,
  useCallback,
  useEffect,
  useState,
  createRef,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import './viewportPrintForm.styl';
import { TextInput, Select, Icon } from '@ohif/ui';
import classnames from 'classnames';

const PRINT_FORMATS = [
  {
    key: 'A4',
    value: 'A4',
  },
  {
    key: 'A5',
    value: 'A5',
  },
];

const PRINT_FORMATS_SIZES =
  // sizes [width, height] in mm
  {
    A4: [210, 297],
    A5: [148, 210],
  };

const DEFAULT_PRINT_FORMAT = 'A4';
const REFRESH_VIEWPORT_TIMEOUT = 1000;

const ViewportPrintForm = ({
  activeViewport,
  onClose,
  updateViewportPreview,
  enableViewport,
  disableViewport,
  toggleAnnotations,
  loadImage,
  printBlob,
  defaultSize,
  minimumSize,
  maximumSize,
  canvasClass,
  defaultDimensions,
  defaultAspectMultiplier,
}) => {
  const [t] = useTranslation('ViewportPrintForm');

  const [printFormat, setPrintFormat] = useState(DEFAULT_PRINT_FORMAT);

  const [dimensions, setDimensions] = useState({
    width: defaultDimensions.width ? defaultDimensions.width : defaultSize,
    height: defaultDimensions.height ? defaultDimensions.height : defaultSize,
  });

  const [showAnnotations, setShowAnnotations] = useState(true);

  const [keepAspect, setKeepAspect] = useState(true);
  const [aspectMultiplier, setAspectMultiplier] = useState({
    width: defaultAspectMultiplier.width ? defaultAspectMultiplier.width : 1,
    height: defaultAspectMultiplier.height ? defaultAspectMultiplier.height : 1,
  });

  const [viewportElement, setViewportElement] = useState();
  const [viewportElementDimensions, setViewportElementDimensions] = useState({
    width: defaultSize,
    height: defaultSize,
  });

  const [downloadCanvas, setDownloadCanvas] = useState({
    ref: createRef(),
    width: defaultSize,
    height: defaultSize,
  });

  const [viewportPreview, setViewportPreview] = useState({
    src: null,
    width: defaultSize,
    height: defaultSize,
  });

  const [error, setError] = useState({
    width: false,
    height: false,
  });

  const hasError = Object.values(error).includes(true);

  const refreshViewport = useRef(null);

  const [rowPixelSpacing, setRowPixelSpacing] = useState();
  const [columnPixelSpacing, setColumnPixelSpacing] = useState();

  const printImage = () => {
    printBlob(printFormat, viewportElement, downloadCanvas.ref.current);
  };

  /**
   * @param {object} event - Input change event
   * @param {string} dimension - "height" | "width"
   */
  const onDimensionsChange = (event, dimension) => {
    const oppositeDimension = dimension === 'height' ? 'width' : 'height';
    const sanitizedTargetValue = event.target.value.replace(/\D/, '');
    const isEmpty = sanitizedTargetValue === '';
    const newDimensions = { ...dimensions };
    const updatedDimension = isEmpty
      ? ''
      : Math.min(sanitizedTargetValue, maximumSize);

    if (updatedDimension === dimensions[dimension]) {
      return;
    }

    newDimensions[dimension] = updatedDimension;

    if (keepAspect && newDimensions[oppositeDimension] !== '') {
      newDimensions[oppositeDimension] =
        aspectMultiplier[oppositeDimension] > aspectMultiplier[dimension]
          ? Math.round(
              newDimensions[dimension] * aspectMultiplier[oppositeDimension]
            )
          : Math.round(newDimensions[dimension] / aspectMultiplier[dimension]);
    }

    // In current code, keepAspect is always `true`
    // And we always start w/ a square width/height
    setDimensions(newDimensions);

    // Only update if value is non-empty
    if (!isEmpty) {
      setViewportElementDimensions(newDimensions);
      setDownloadCanvas(state => ({
        ...state,
        ...newDimensions,
      }));
    }
  };

  const onPrintFormatChange = printformat => {
    const newDimensions = { ...dimensions };
    if (columnPixelSpacing && rowPixelSpacing) {
      newDimensions.width =
        Math.round(PRINT_FORMATS_SIZES[printformat][0] / columnPixelSpacing) -
        100;
      newDimensions.height =
        Math.round(PRINT_FORMATS_SIZES[printformat][1] / rowPixelSpacing) - 100;
    } else {
      newDimensions.width =
        Math.round(PRINT_FORMATS_SIZES[printformat][0] / 0.175) - 100;
      newDimensions.height =
        Math.round(PRINT_FORMATS_SIZES[printformat][1] / 0.175) - 100;
    }
    const oppositeDimension = 'height';
    const dimension = 'width';

    if (newDimensions.width > newDimensions.height) {
      const backup_width = newDimensions.width;
      newDimensions.width = newDimensions.height;
      newDimensions.height = backup_width;
    }

    if (keepAspect && newDimensions[oppositeDimension] !== '') {
      newDimensions[oppositeDimension] =
        aspectMultiplier[oppositeDimension] > aspectMultiplier[dimension]
          ? Math.round(
              newDimensions[dimension] * aspectMultiplier[oppositeDimension]
            )
          : Math.round(newDimensions[dimension] / aspectMultiplier[dimension]);
    }
    setPrintFormat(printformat);
    setDimensions(newDimensions);
    setViewportElementDimensions(newDimensions);
    setDownloadCanvas(state => ({
      ...state,
      ...newDimensions,
    }));
  };

  const error_messages = {
    width: t('minWidthError'),
    height: t('minHeightError'),
  };

  const renderErrorHandler = errorType => {
    if (!error[errorType]) {
      return null;
    }

    return <div className="input-error">{error_messages[errorType]}</div>;
  };

  const onKeepAspectToggle = () => {
    const { width, height } = dimensions;
    const aspectMultiplier = { ...aspectMultiplier };
    if (!keepAspect) {
      const base = Math.min(width, height);
      aspectMultiplier.width = width / base;
      aspectMultiplier.height = height / base;
      setAspectMultiplier(aspectMultiplier);
    }

    setKeepAspect(!keepAspect);
  };

  const validSize = value => (value >= minimumSize ? value : minimumSize);
  const loadAndUpdateViewports = useCallback(async () => {
    const {
      image: loadedImage,
      width: scaledWidth,
      height: scaledHeight,
    } = await loadImage(
      activeViewport,
      viewportElement,
      dimensions.width,
      dimensions.height
    );

    toggleAnnotations(showAnnotations, viewportElement);

    const scaledDimensions = {
      height: validSize(scaledHeight),
      width: validSize(scaledWidth),
    };

    if (loadedImage) {
      setRowPixelSpacing(loadedImage.rowPixelSpacing);
      setColumnPixelSpacing(loadedImage.columnPixelSpacing);
    }
    setViewportElementDimensions(scaledDimensions);
    setDownloadCanvas(state => ({
      ...state,
      ...scaledDimensions,
    }));

    const {
      dataUrl,
      width: viewportElementWidth,
      height: viewportElementHeight,
    } = await updateViewportPreview(
      viewportElement,
      downloadCanvas.ref.current
    );

    setViewportPreview(state => ({
      ...state,
      src: dataUrl,
      width: validSize(viewportElementWidth),
      height: validSize(viewportElementHeight),
    }));
  }, [
    loadImage,
    activeViewport,
    viewportElement,
    dimensions.width,
    dimensions.height,
    toggleAnnotations,
    showAnnotations,
    validSize,
    updateViewportPreview,
    downloadCanvas.ref,
  ]);

  useEffect(() => {
    enableViewport(viewportElement);

    return () => {
      disableViewport(viewportElement);
    };
  }, [disableViewport, enableViewport, viewportElement]);

  useEffect(() => {
    if (refreshViewport.current !== null) {
      clearTimeout(refreshViewport.current);
    }

    refreshViewport.current = setTimeout(() => {
      refreshViewport.current = null;
      loadAndUpdateViewports();
    }, REFRESH_VIEWPORT_TIMEOUT);
  }, [
    activeViewport,
    viewportElement,
    showAnnotations,
    dimensions,
    loadImage,
    toggleAnnotations,
    updateViewportPreview,
    downloadCanvas.ref,
    minimumSize,
    maximumSize,
    loadAndUpdateViewports,
  ]);

  useEffect(() => {
    const { width, height } = dimensions;
    const hasError = {
      width: width < minimumSize,
      height: height < minimumSize,
    };

    setError({ ...hasError });
  }, [dimensions, minimumSize]);

  return (
    <div className="ViewportPrintForm">
      <div className="title">{t('formTitle')}</div>

      <div className="file-info-container" data-cy="file-info-container">
        <div className="dimension-wrapper">
          <div className="dimensions">
            <div className="print-format">
              <Select
                value={printFormat}
                data-cy="print-format"
                onChange={event => onPrintFormatChange(event.target.value)}
                options={PRINT_FORMATS}
                label={t('printFormat')}
              />
            </div>
            <div className="width">
              <TextInput
                type="number"
                min={minimumSize}
                max={maximumSize}
                value={dimensions.width}
                label={t('imageWidth')}
                onChange={evt => onDimensionsChange(evt, 'width')}
                data-cy="image-width"
              />
              {renderErrorHandler('width')}
            </div>
            <div className="height">
              <TextInput
                type="number"
                min={minimumSize}
                max={maximumSize}
                value={dimensions.height}
                label={t('imageHeight')}
                onChange={evt => onDimensionsChange(evt, 'height')}
                data-cy="image-height"
              />
              {renderErrorHandler('height')}
            </div>
          </div>
          <div className="keep-aspect-wrapper">
            <button
              id="keep-aspect"
              className={classnames(
                'form-button btn',
                keepAspect ? 'active' : ''
              )}
              data-cy="keep-aspect"
              alt={t('keepAspectRatio')}
              onClick={onKeepAspectToggle}
            >
              <Icon
                name={keepAspect ? 'link' : 'unlink'}
                alt={keepAspect ? 'Dismiss Aspect' : 'Keep Aspect'}
              />
            </button>
          </div>
        </div>

        <div className="col">
          <div className="show-annotations">
            <label htmlFor="show-annotations" className="form-check-label">
              <input
                id="show-annotations"
                data-cy="show-annotations"
                type="checkbox"
                className="form-check-input"
                checked={showAnnotations}
                onChange={event => setShowAnnotations(event.target.checked)}
              />
              {t('showAnnotations')}
            </label>
          </div>
        </div>
      </div>

      <div
        style={{
          height: viewportElementDimensions.height,
          width: viewportElementDimensions.width,
          position: 'absolute',
          left: '9999px',
        }}
        ref={ref => setViewportElement(ref)}
      >
        <canvas
          className={canvasClass}
          style={{
            height: downloadCanvas.height,
            width: downloadCanvas.width,
            display: 'block',
          }}
          width={downloadCanvas.width}
          height={downloadCanvas.height}
          ref={downloadCanvas.ref}
        ></canvas>
      </div>

      {viewportPreview.src ? (
        <div className="preview" data-cy="image-preview">
          <div className="preview-header"> {t('imagePreview')}</div>
          <img
            className="viewport-preview"
            src={viewportPreview.src}
            alt={t('imagePreview')}
            data-cy="image-preview"
            data-cy="viewport-preview-img"
          />
        </div>
      ) : (
        <div className="loading-image">
          <Icon name="circle-notch" className="icon-spin" />
          {t('loadingPreview')}
        </div>
      )}

      <div className="actions">
        <div className="action-cancel">
          <button
            type="button"
            data-cy="cancel-btn"
            className="btn btn-danger"
            onClick={onClose}
          >
            {t('Buttons:Cancel')}
          </button>
        </div>
        <div className="action-save">
          <button
            disabled={hasError}
            onClick={printImage}
            className="btn btn-primary"
            data-cy="print-btn"
          >
            {t('Buttons:Print')}
          </button>
        </div>
      </div>
    </div>
  );
};

ViewportPrintForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  activeViewport: PropTypes.object,
  updateViewportPreview: PropTypes.func.isRequired,
  enableViewport: PropTypes.func.isRequired,
  disableViewport: PropTypes.func.isRequired,
  toggleAnnotations: PropTypes.func.isRequired,
  loadImage: PropTypes.func.isRequired,
  printBlob: PropTypes.func.isRequired,
  /** A default width & height, between the minimum and maximum size */
  defaultSize: PropTypes.number.isRequired,
  minimumSize: PropTypes.number.isRequired,
  maximumSize: PropTypes.number.isRequired,
  canvasClass: PropTypes.string.isRequired,
  defaultDimensions: PropTypes.object.isRequired,
  defaultAspectMultiplier: PropTypes.object.isRequired,
};

export default ViewportPrintForm;
