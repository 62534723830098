import React, { useEffect, useState } from 'react';
import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import PropTypes from 'prop-types';

import { ViewportPrintForm } from '@ohif/ui';
import { utils } from '@ohif/core';

import { getEnabledElement } from './state';

const MINIMUM_SIZE = 100;
const DEFAULT_SIZE = 512;
const MAX_TEXTURE_SIZE = 10000;

const PRINT_FORMATS_SIZES =
  // sizes [width, height] in mm
  {
    A4: [210, 297],
    A5: [148, 210],
  };

const DEFAULT_PRINT_FORMAT = 'A4';

const CornerstoneViewportPrintForm = ({ onClose, activeViewportIndex }) => {
  const activeEnabledElement = getEnabledElement(activeViewportIndex);
  const [baseDimensions, setBaseDimensions] = useState({
    width: DEFAULT_SIZE,
    height: DEFAULT_SIZE,
  });
  const [aspectMultiplier, setAspectMultiplier] = useState({
    width: 1,
    height: 1,
  });
  const [imageAnalyzed, setImageAnalyzed] = useState(false);

  const enableViewport = viewportElement => {
    if (viewportElement) {
      cornerstone.enable(viewportElement);
    }
  };

  const disableViewport = viewportElement => {
    if (viewportElement) {
      cornerstone.disable(viewportElement);
    }
  };

  const updateViewportPreview = (viewportElement, downloadCanvas) =>
    new Promise(resolve => {
      cornerstone.fitToWindow(viewportElement);

      viewportElement.addEventListener(
        'cornerstoneimagerendered',
        function updateViewport(event) {
          const enabledElement = cornerstone.getEnabledElement(event.target)
            .element;
          const type = 'image/png';
          const dataUrl = downloadCanvas.toDataURL(type, 1);

          let newWidth = enabledElement.offsetHeight;
          let newHeight = enabledElement.offsetWidth;

          if (newWidth > DEFAULT_SIZE || newHeight > DEFAULT_SIZE) {
            const multiplier = DEFAULT_SIZE / Math.max(newWidth, newHeight);
            newHeight *= multiplier;
            newWidth *= multiplier;
          }

          resolve({ dataUrl, width: newWidth, height: newHeight });

          viewportElement.removeEventListener(
            'cornerstoneimagerendered',
            updateViewport
          );
        }
      );
    });

  const loadImage = (activeViewport, viewportElement, width, height) =>
    new Promise(resolve => {
      if (activeViewport && viewportElement) {
        const enabledElement = cornerstone.getEnabledElement(activeViewport);
        const viewport = Object.assign({}, enabledElement.viewport);
        delete viewport.scale;
        viewport.translation = {
          x: 0,
          y: 0,
        };

        cornerstone.loadImage(enabledElement.image.imageId).then(image => {
          cornerstone.displayImage(viewportElement, image);
          cornerstone.setViewport(viewportElement, viewport);
          cornerstone.resize(viewportElement, true);

          const newWidth = Math.min(width || image.width, MAX_TEXTURE_SIZE);
          const newHeight = Math.min(height || image.height, MAX_TEXTURE_SIZE);

          resolve({ image, width: newWidth, height: newHeight });
        });
      }
    });

  const toggleAnnotations = (toggle, viewportElement) => {
    cornerstoneTools.store.state.tools.forEach(({ name }) => {
      if (toggle) {
        cornerstoneTools.setToolEnabledForElement(viewportElement, name);
      } else {
        cornerstoneTools.setToolDisabledForElement(viewportElement, name);
      }
    });
  };

  useEffect(() => {
    if (activeEnabledElement) {
      const enabledElement = cornerstone.getEnabledElement(
        activeEnabledElement
      );
      const viewport = Object.assign({}, enabledElement.viewport);
      delete viewport.scale;
      viewport.translation = {
        x: 0,
        y: 0,
      };

      cornerstone.loadImage(enabledElement.image.imageId).then(image => {
        const newAspectMul = { ...aspectMultiplier };
        const base = Math.min(image.width, image.height);
        newAspectMul.width = image.width / base;
        newAspectMul.height = image.height / base;

        let oppositeDimension;
        let dimension;
        if (base == image.width) {
          oppositeDimension = 'height';
          dimension = 'width';
        } else {
          oppositeDimension = 'width';
          dimension = 'height';
        }

        const newDimensions = { ...baseDimensions };
        if (image.columnPixelSpacing && image.rowPixelSpacing) {
          newDimensions.width =
            Math.round(
              PRINT_FORMATS_SIZES[DEFAULT_PRINT_FORMAT][0] /
                image.columnPixelSpacing
            ) - 100;
          newDimensions.height =
            Math.round(
              PRINT_FORMATS_SIZES[DEFAULT_PRINT_FORMAT][1] /
                image.rowPixelSpacing
            ) - 100;
        } else {
          newDimensions.width =
            Math.round(PRINT_FORMATS_SIZES[DEFAULT_PRINT_FORMAT][0] / 0.177) -
            100;
          newDimensions.height =
            Math.round(PRINT_FORMATS_SIZES[DEFAULT_PRINT_FORMAT][1] / 0.177) -
            100;
        }
        //horizontal image
        if (image.width > image.height) {
          const backup_width = newDimensions.width;
          newDimensions.width = newDimensions.height;
          newDimensions.height = backup_width;
        }
        newDimensions[oppositeDimension] =
          newAspectMul[oppositeDimension] > newAspectMul[dimension]
            ? Math.round(
                newDimensions[dimension] * newAspectMul[oppositeDimension]
              )
            : Math.round(newDimensions[dimension] / newAspectMul[dimension]);
        setBaseDimensions(newDimensions);
        setAspectMultiplier(newAspectMul);
        setImageAnalyzed(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const printBlob = (printFormat, viewportElement, downloadCanvas) => {
    var dataUrl = downloadCanvas.toDataURL();
    var windowContent = '<!DOCTYPE html>';
    windowContent += '<html>';
    windowContent += '<body>';
    windowContent += '<img src="' + dataUrl + '">';
    windowContent += '</body>';
    windowContent += '</html>';
    var printWin = window.open();
    printWin.document.open();
    printWin.document.write(windowContent);
    printWin.document.close();
    printWin.focus();
    printWin.print();
    printWin.close();
  };

  return (
    <>
      {imageAnalyzed ? (
        <ViewportPrintForm
          onClose={onClose}
          minimumSize={MINIMUM_SIZE}
          maximumSize={MAX_TEXTURE_SIZE}
          defaultSize={DEFAULT_SIZE}
          defaultDimensions={baseDimensions}
          defaultAspectMultiplier={aspectMultiplier}
          canvasClass={'cornerstone-canvas'}
          activeViewport={activeEnabledElement}
          enableViewport={enableViewport}
          disableViewport={disableViewport}
          updateViewportPreview={updateViewportPreview}
          loadImage={loadImage}
          toggleAnnotations={toggleAnnotations}
          printBlob={printBlob}
        />
      ) : (
        ''
      )}
    </>
  );
};

CornerstoneViewportPrintForm.propTypes = {
  onClose: PropTypes.func,
  activeViewportIndex: PropTypes.number.isRequired,
};

export default CornerstoneViewportPrintForm;
